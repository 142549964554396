/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Divider,
  Hidden,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import i18n from '@utils/i18n';
import React, { useState } from 'react';

import InstagramIcon from '@mui/icons-material/Instagram';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ReactComponent as BlogIcon } from '@svg/blogx.svg';
import TermsAndPolicy from './TermsAndPolicy';

const labelStyle = {
  fontWeight: 500,
  fontFamily: 'Pretendard',
  color: '#919193',
  fontSize: { md: 12, xs: 'clamp(0.75rem, 0.6055rem + 0.6163vw, 1rem)' },
  lineHeight: '140%'
};
const valueStyle = {
  fontWeight: 500,
  fontFamily: 'Pretendard',
  color: '#E3E3E3',
  fontSize: { md: 12, xs: 'clamp(0.75rem, 0.6055rem + 0.6163vw, 1rem)' },
  lineHeight: '140%',
  letterSpacing: '-0.036rem'
};
const Footer = () => {
  const aboutT = i18n.getFixedT(null, null, 'pages');
  const [dialog, setDialog] = useState({
    open: false,
    type: ''
  });
  return (
    <Stack
      sx={{
        backgroundColor: { md: '#333438', xs: '#202020' },
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: { md: 'auto', xs: 'auto' },
        color: '#BEBEBE'
      }}
    >
      <Stack
        sx={{
          width: { md: '75%', lg: '100%', xs: '100%' },
          height: '100%',
          maxWidth: '1920px',
          px: { lg: '320px', xs: '20px' },
          py: { md: '40px', xs: '32px' }
        }}
      >
        <Hidden mdDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack gap={1}>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                gap={1}
              >
                {/* comapny name */}
                <Typography sx={valueStyle}>
                  {aboutT('footer.companyName')}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ border: '1px solid #5D5D5D' }}
                />
                {/* ceo */}
                <Typography sx={labelStyle}>{aboutT('footer.ceo')}</Typography>
                <Typography sx={valueStyle}>
                  {aboutT('footer.ceo-name')}
                </Typography>
              </Stack>
              {/* business registration */}
              <Stack
                sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}
              >
                <Typography sx={labelStyle}>
                  {aboutT('footer.business-registration')}
                </Typography>
                <Typography sx={valueStyle}>
                  {aboutT('footer.company-registration')}
                </Typography>
              </Stack>

              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                gap={1}
              >
                {/* mailAddress */}
                <Typography sx={labelStyle}>
                  {aboutT('footer.mail-order')}
                </Typography>
                <Typography sx={valueStyle}>
                  {aboutT('footer.mail-address')}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                gap={1}
              >
                {/* addresss */}
                <Typography sx={labelStyle}>
                  {aboutT('footer.address')}
                </Typography>
                <Typography sx={valueStyle}>
                  {aboutT('footer.office-address')}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                gap={1}
              >
                {/* phone number */}
                <Typography sx={labelStyle}>
                  {aboutT('footer.ceo-phone')}
                </Typography>
                <Typography sx={valueStyle}>02-2038-3679</Typography>
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                gap={1}
              >
                {/* phone number */}
                <Typography sx={labelStyle}>
                  {aboutT('footer.partnership')}
                </Typography>
                <Typography sx={valueStyle}>
                  {' '}
                  partner@workershigh.com
                </Typography>
              </Stack>
            </Stack>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              gap={2}
            >
              <Stack
                display="flex"
                sx={{ width: '100%', justifyContent: 'flex-end' }}
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                {/* <IconButton
                  href="https://www.instagram.com/workershigh_official/"
                  target="_blank"
                  sx={{
                    '&.MuiButtonBase-root:hover ': {
                      backgroundColor: { md: 'white' }
                    },
                    backgroundColor: '#7B7C80',
                    borderRadius: 1,
                    width: '38px',
                    height: '38px'
                  }}
                >
                  <InstagramIcon
                    sx={{
                      width: '26px',
                      height: '26px',
                      color: '#333438'
                    }}
                  />
                </IconButton>
                <IconButton
                  href="https://blog.naver.com/workershigh"
                  target="_blank"
                  sx={{
                    '&.MuiButtonBase-root:hover ': {
                      backgroundColor: { md: 'white' },
                      color: 'white'
                    },
                    backgroundColor: '#7B7C80',
                    borderRadius: 1,
                    width: '38px',
                    height: '38px'
                  }}
                >
                  <BlogIcon />{' '}
                </IconButton>
           
                <IconButton
                  href="https://www.linkedin.com/company/workershigh/?originalSubdomain=kr"
                  target="_blank"
                  sx={{
                    '&.MuiButtonBase-root:hover ': {
                      backgroundColor: { md: 'white' }
                    },
                    backgroundColor: '#7B7C80',
                    borderRadius: 1,
                    width: '38px',
                    height: '38px'
                  }}
                >
                  <LinkedInIcon
                    sx={{
                      width: '26px',
                      height: '26px',
                      color: '#333438',
                      backgroundColor: 'transparent'
                    }}
                  />{' '}
                </IconButton> */}
              </Stack>
              <Stack textAlign="right" gap={2}>
                <Stack
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    textAlign: 'right',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    sx={{ ...valueStyle, cursor: 'pointer' }}
                    onClick={() =>
                      setDialog((prev) => ({
                        ...prev,
                        open: true,
                        type: 'terms'
                      }))
                    }
                  >
                    {aboutT('footer.privacyPolicy')}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ border: '1px solid #5D5D5D' }}
                  />
                  <Typography
                    sx={{ ...valueStyle, cursor: 'pointer' }}
                    onClick={() =>
                      setDialog((prev) => ({
                        ...prev,
                        open: true,
                        type: 'policy'
                      }))
                    }
                  >
                    {aboutT('footer.notice')}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography sx={valueStyle}>
                    COPYRIGHT © Worker's high Co.,Ltd. ALL RIGHTS RESERVED.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          {dialog.type && (
            <TermsAndPolicy
              open={dialog?.open}
              type={dialog.type}
              setOpen={() => setDialog({ open: false, type: '' })}
            />
          )}
        </Hidden>
        <Hidden mdUp>
          <Stack
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Stack gap={1}>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                gap={1}
              >
                {/* comapny name */}
                <Typography sx={valueStyle}>
                  {aboutT('footer.companyName')}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ border: '1px solid #5D5D5D' }}
                />
                {/* ceo */}
                <Typography sx={labelStyle}>{aboutT('footer.ceo')}</Typography>
                <Typography sx={valueStyle}>
                  {aboutT('footer.ceo-name')}
                </Typography>
              </Stack>

              {/* business registration */}
              <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Typography sx={labelStyle}>
                  {aboutT('footer.business-registration')}
                </Typography>
                <Typography sx={valueStyle}>
                  {aboutT('footer.company-registration')}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
                gap={1}
              >
                {/* mailAddress */}
                <Typography sx={labelStyle}>
                  {aboutT('footer.mail-order')}
                </Typography>
                <Typography sx={valueStyle}>
                  {aboutT('footer.mail-address')}
                </Typography>
              </Stack>
              {/* address */}
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start'
                }}
                gap={1}
              >
                {/* addresss */}
                <Typography sx={labelStyle}>
                  {aboutT('footer.address')}
                </Typography>
                <Typography sx={valueStyle}>
                  {aboutT('footer.office-address')}
                </Typography>

                {/* phone number */}
              </Stack>
              {/*  business registration */}
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
                gap={1}
              >
                <Typography sx={labelStyle}>
                  {aboutT('footer.ceo-phone')}
                </Typography>
                <Typography sx={valueStyle}>02-2038-3679</Typography>
              </Stack>

              {/* partnership */}
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
                gap={1}
                mb={2.5}
              >
                <Typography sx={labelStyle}>
                  {aboutT('footer.partnership')}
                </Typography>
                <Typography sx={valueStyle}>
                  {' '}
                  partner@workershigh.com
                </Typography>
              </Stack>
            </Stack>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start'
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{ ...labelStyle, cursor: 'pointer', fontWeight: 500 }}
                  onClick={() =>
                    setDialog((prev) => ({
                      ...prev,
                      open: true,
                      type: 'terms'
                    }))
                  }
                >
                  {aboutT('footer.privacyPolicy')}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ border: '1px solid #5D5D5D' }}
                />
                <Typography
                  sx={{ ...labelStyle, cursor: 'pointer', fontWeight: 700 }}
                  onClick={() =>
                    setDialog((prev) => ({
                      ...prev,
                      open: true,
                      type: 'policy'
                    }))
                  }
                >
                  {aboutT('footer.notice')}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 0.5 }}>
                <Typography
                  sx={{ ...labelStyle, fontWeight: 400, color: '#A0A0A0' }}
                >
                  COPYRIGHT © Worker's high Co.,Ltd. ALL RIGHTS RESERVED.
                </Typography>
              </Stack>
              <Stack
                display="none"
                flexDirection="row"
                alignItems="center"
                gap={1}
                mt={2.5}
              >
                <IconButton
                  href="https://www.instagram.com/workershigh_official/"
                  target="_blank"
                  sx={{
                    '&.MuiButtonBase-root:hover ': {
                      backgroundColor: { md: 'white', xs: '#919193' }
                    },
                    backgroundColor: '#919193',
                    borderRadius: 1,
                    width: '38px',
                    height: '38px'
                  }}
                >
                  <InstagramIcon
                    sx={{
                      width: '26px',
                      height: '26px',
                      color: '#333438'
                    }}
                  />
                </IconButton>{' '}
                <IconButton
                  href="https://blog.naver.com/workershigh"
                  target="_blank"
                  sx={{
                    '&.MuiButtonBase-root:hover ': {
                      backgroundColor: { md: 'white', xs: '#919193' },
                      color: 'white'
                    },
                    backgroundColor: '#919193',
                    borderRadius: 1,
                    width: '38px',
                    height: '38px'
                  }}
                >
                  <BlogIcon />{' '}
                </IconButton>
                {/* <BlogIcon fill="#7B7C80" />{' '} */}
                <IconButton
                  href="https://www.linkedin.com/company/workershigh/?originalSubdomain=kr"
                  target="_blank"
                  sx={{
                    '&.MuiButtonBase-root:hover ': {
                      backgroundColor: { md: 'white', xs: '#919193' }
                    },
                    backgroundColor: '#919193',
                    borderRadius: 1,
                    width: '38px',
                    height: '38px'
                  }}
                >
                  <LinkedInIcon
                    sx={{
                      width: '26px',
                      height: '26px',
                      color: '#333438',
                      backgroundColor: 'transparent'
                    }}
                  />{' '}
                </IconButton>
              </Stack>
            </Box>
          </Stack>
          {dialog.type && (
            <TermsAndPolicy
              open={dialog?.open}
              type={dialog.type}
              setOpen={() => setDialog({ open: false, type: '' })}
            />
          )}
        </Hidden>
      </Stack>
    </Stack>
  );
};

export default Footer;
